body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: MarkGEO;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MarkGEO';
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Light/MarkGEO-Light.eot');
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Light/MarkGEO-Light.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Light/MarkGEO-Light.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Light/MarkGEO-Light.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Light/MarkGEO-Light.svg#MarkGEO-Light')
      format('svg'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Light/MarkGEO-Light.eot?#iefix')
      format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MarkGEO';
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Regular/MarkGEO-Regular.eot');
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Regular/MarkGEO-Regular.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Regular/MarkGEO-Regular.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Regular/MarkGEO-Regular.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Regular/MarkGEO-Regular.svg#MarkGEO-Regular')
      format('svg'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Regular/MarkGEO-Regular.eot?#iefix')
      format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MarkGEO';
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Medium/MarkGEO-Medium.eot');
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Medium/MarkGEO-Medium.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Medium/MarkGEO-Medium.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Medium/MarkGEO-Medium.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Medium/MarkGEO-Medium.svg#MarkGEO-Medium')
      format('svg'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Medium/MarkGEO-Medium.eot?#iefix')
      format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MarkGEO';
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-SemiBold/MarkGEO-SemiBold.eot');
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-SemiBold/MarkGEO-SemiBold.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-SemiBold/MarkGEO-SemiBold.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-SemiBold/MarkGEO-SemiBold.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-SemiBold/MarkGEO-SemiBold.svg#MarkGEO-SemiBold')
      format('svg'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-SemiBold/MarkGEO-SemiBold.eot?#iefix')
      format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MarkGEO';
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Bold/MarkGEO-Bold.eot');
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Bold/MarkGEO-Bold.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Bold/MarkGEO-Bold.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Bold/MarkGEO-Bold.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Bold/MarkGEO-Bold.svg#MarkGEO-Bold')
      format('svg'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEO-Bold/MarkGEO-Bold.eot?#iefix')
      format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MarkGEOCAPS';
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Light/MarkGEOCAPS-Light.eot');
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Light/MarkGEOCAPS-Light.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Light/MarkGEOCAPS-Light.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Light/MarkGEOCAPS-Light.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Light/MarkGEOCAPS-Light.svg#MarkGEOCAPS-Light')
      format('svg'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Light/MarkGEOCAPS-Light.eot?#iefix')
      format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MarkGEOCAPS';
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Regular/MarkGEOCAPS-Regular.eot');
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Regular/MarkGEOCAPS-Regular.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Regular/MarkGEOCAPS-Regular.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Regular/MarkGEOCAPS-Regular.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Regular/MarkGEOCAPS-Regular.svg#MarkGEOCAPS-Regular')
      format('svg'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Regular/MarkGEOCAPS-Regular.eot?#iefix')
      format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MarkGEOCAPS';
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Medium/MarkGEOCAPS-Medium.eot');
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Medium/MarkGEOCAPS-Medium.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Medium/MarkGEOCAPS-Medium.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Medium/MarkGEOCAPS-Medium.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Medium/MarkGEOCAPS-Medium.svg#MarkGEOCAPS-Medium')
      format('svg'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Medium/MarkGEOCAPS-Medium.eot?#iefix')
      format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MarkGEOCAPS';
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-SemiBold/MarkGEOCAPS-SemiBold.eot');
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-SemiBold/MarkGEOCAPS-SemiBold.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-SemiBold/MarkGEOCAPS-SemiBold.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-SemiBold/MarkGEOCAPS-SemiBold.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-SemiBold/MarkGEOCAPS-SemiBold.svg#MarkGEOCAPS-SemiBold')
      format('svg'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-SemiBold/MarkGEOCAPS-SemiBold.eot?#iefix')
      format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MarkGEOCAPS';
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Bold/MarkGEOCAPS-Bold.eot');
  src: url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Bold/MarkGEOCAPS-Bold.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Bold/MarkGEOCAPS-Bold.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Bold/MarkGEOCAPS-Bold.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Bold/MarkGEOCAPS-Bold.svg#MarkGEOCAPS-Bold')
      format('svg'),
    url('https://webstatic.bog.ge/fonts/markgeo/MarkGEOCAPS-Bold/MarkGEOCAPS-Bold.eot?#iefix')
      format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-UltraBold.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-UltraBold.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-UltraBold.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-UltraBold.woff') format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-UltraBold.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-UltraBold.svg#BOG-UltraBold')
      format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Black.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Black.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Black.woff2') format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Black.woff') format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Black.ttf') format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Black.svg#BOG-Black')
      format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Bold.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Bold.woff2') format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Bold.woff') format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Bold.ttf') format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Bold.svg#BOG-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG Headline';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Medium.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Medium.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Medium.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Medium.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Medium.svg#BOG-Headline-Medium')
      format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Regular.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Regular.woff2') format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Regular.woff') format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Regular.ttf') format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Regular.svg#BOG-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG Headline';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Black.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Black.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Black.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Black.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Black.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Black.svg#BOG-Headline-Black')
      format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Medium.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Medium.woff2') format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Medium.woff') format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Medium.ttf') format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Medium.svg#BOG-Medium')
      format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG Headline';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Regular.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Regular.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Regular.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Regular.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Regular.svg#BOG-Headline-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG Headline';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Light.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Light.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Light.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Light.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Light.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Light.svg#BOG-Headline-Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG Headline';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-UltraBold.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-UltraBold.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-UltraBold.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-UltraBold.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-UltraBold.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-UltraBold.svg#BOG-Headline-UltraBold')
      format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG Headline';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-SemiBold.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-SemiBold.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-SemiBold.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-SemiBold.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-SemiBold.svg#BOG-Headline-SemiBold')
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG Headline';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Bold.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Bold.woff2')
      format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Bold.woff')
      format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Bold.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Headline-Bold.svg#BOG-Headline-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-SemiBold.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-SemiBold.woff2') format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-SemiBold.woff') format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-SemiBold.ttf')
      format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-SemiBold.svg#BOG-SemiBold')
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BOG';
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Light.eot');
  src: url('https://webstatic.bog.ge/fonts/BOG/BOG-Light.eot?#iefix')
      format('embedded-opentype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Light.woff2') format('woff2'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Light.woff') format('woff'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Light.ttf') format('truetype'),
    url('https://webstatic.bog.ge/fonts/BOG/BOG-Light.svg#BOG-Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
